import React from 'react';
import PortableText from 'react-portable-text';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { Blurbs } from './Blurbs';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: '4rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '1rem',
		},
	},
	introHeader: {
		lineHeight: 1.2,
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			lineHeight: 1.2,
			textAlign: 'left',
			fontSize: '1.6rem',
		},
	},
	highlightIntro: {
		lineHeight: 1.2,
		fontWeight: 700,
		color: theme.realgreenGreen,
		[theme.breakpoints.down('xs')]: {
			lineHeight: 1.2,
			textAlign: 'left',
		},
	},
	paragraph: {
		lineHeight: '36px',
		marginTop: '2rem',
		// fontWeight: 600,
		color: '#4B5B69',
		fontFamily: 'Roboto',
		fontSize: '1.25rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.125rem',
		},
		[theme.breakpoints.down('xs')]: {
			lineHeight: '32px',
			textAlign: 'left',
		},
	},
	blurbRoot: {
		[theme.breakpoints.down('md')]: {
			marginTop: '2rem',
		},
	},
	lineBreak: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
}));

export const Intro = ({ intro }) => {
	const classes = useStyles();
	const { _rawLeftContent, rightSideBlurbs } = intro;

	return (
		<Grid
			container
			justifyContent='space-between'
			className={classes.root}
			spacing={2}>
			<Grid item xs={12} md={6} container justifyContent='center'>
				<PortableText
					content={_rawLeftContent}
					serializers={{
						h2: ({ children }) => (
							<Typography
								variant='h2'
								color='primary'
								className={classes.introHeader}>
								{children}
							</Typography>
						),
						strong: ({ children }) => (
							<>
								<br className={classes.lineBreak} />
								<span className={classes.highlightIntro}>{children}</span>
							</>
						),
						normal: ({ children }) => (
							<Typography variant='body1' className={classes.paragraph}>
								{children}
							</Typography>
						),
					}}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={5}
				container
				justifyContent='center'
				className={classes.blurbRoot}>
				{rightSideBlurbs.map((blurb, index) => (
					<Blurbs blurb={blurb} key={index} />
				))}
			</Grid>
		</Grid>
	);
};
