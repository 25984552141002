import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortableText from 'react-portable-text';
import ReactTextTransition, { presets } from 'react-text-transition';
import { Link, navigate, Script } from 'gatsby';
import loadable from '@loadable/component';

// import ReactPlayer from 'react-player/lazy';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container, useMediaQuery } from '@material-ui/core';

import { WaveUpHero } from '../../WaveSVGs/WaveUpHero';
import { IndustrySearch } from './IndustrySearch';
// import home from '../../../assets/home-video.mp4';
// import { Video } from './Video';

const Video = loadable(() => import('./Video'));

const useStyles = makeStyles((theme) => ({
  '@keyframes pulseBorder': {
    '0%': {
      transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1)',
      opacity: '1',
    },
    '100%': {
      transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1.5)',
      opacity: '0',
    },
  },
  heroPills: {
    margin: '0',
    marginBottom: '0px !important',
    marginTop: '15px !important',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '767px',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      width: '530px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  pillsText: {
    textAlign: 'center',
    width: '100%',
    color: '#002D5C',
    fontWeight: 500,
    padding: '0 10px',
    position: 'relative',
    lineHeight: '1.1',
    fontSize: '.8rem',
    [theme.breakpoints.down('md')]: {
      padding: '0 5px',
      fontSize: '.7rem',
    },
  },

  pillsTextAll: {
    textAlign: 'center',
    width: '100%',
    color: '#002D5C',
    fontWeight: 500,
    padding: '0 10px',
    position: 'relative',
    lineHeight: '1.1',
    fontSize: '.8rem',
    [theme.breakpoints.down('md')]: {
      padding: '0 5px',
      fontSize: '.7rem',
    },
  },
  pills: {
    border: '1px solid #E5EAF4',
    borderRadius: '8px',
    background: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    transition: 'all .5s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  heroPill: {
    width: '23%',
    padding: '0px',
    paddingTop: '0px',
    borderRadius: '8px',

    [theme.breakpoints.down('md')]: {
      width: '30%',
      minWidth: '24%',
    },

    [theme.breakpoints.down('sm')]: {
      width: '50%',
      maxWidth: '50%',
      margin: '0 auto',
      marginBottom: '16px',
    },

    '&:nth-last-of-type(1)': {
      fontSize: '.75rem',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },

    '&:nth-child(even)': {
      padding: '0px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '0px',
        paddingLeft: '8px',
        paddingRight: '0px',
      },
    },
    '&:nth-child(odd)': {
      padding: '0px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '0px',
        paddingLeft: '0px',
        paddingRight: '8px',
      },
    },
  },

  root: {
    position: 'relative',
    width: '100%',
    padding: '6rem 0',
    maxWidth: '100vw',
    overflow: 'hidden',
    background: '#F8FBFF',

    [theme.breakpoints.down('xs')]: {
      padding: '3rem 0',
    },
  },

  bgBlueLight: {
    width: '230px',
    height: '230px',
    background: '#002D5C',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    borderRadius: '50%',
    opacity: '0.75',

    [theme.breakpoints.down('md')]: {
      width: '180px',
      height: '180px',
    },

    [theme.breakpoints.down('sm')]: {
      background: 'rgba(255,255,255,0)',
      top: 0,
      left: 0,
      borderRadius: '0px',
      margin: '0px',
    },
  },

  playButton: {
    height: '100px',
    width: '100px',
    background: '#fff',
    color: '#002d5c',
    fontSize: '1.5rem',
    borderRadius: '50%',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
    cursor: 'pointer',
    zIndex: 5,

    '&::before': {
      content: `''`,
      position: 'absolute',
      zIndex: '-1',
      left: '50%',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      display: 'block',
      width: '100px',
      height: '100px',
      background: '#fff',
      borderRadius: '50%',
    },

    '&:hover': {
      '& $playButtonEffect': { transform: 'scale(1.1)' },
      '&::before': {
        animation: '$pulseBorder 1500ms ease-out infinite',
      },
    },
    // [theme.breakpoints.down('lg')]: {
    //   top: '43%',
    //   left: '109%',
    // },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  heroVideoBg: {
    position: 'absolute',
    zIndex: 3,
    top: '37%',
    left: '92%',
    width: '650px',
    height: '650px',
    transform: 'translateX(-50%) translateY(-50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    alignContent: 'flex-end',
    // boxShadow: '0 0 0 60px #2a7abc, 0 0 0 120px #002d5c',
    borderRadius: '50%',
    overflow: 'auto',

    [theme.breakpoints.down('xl')]: {
      top: '37%',
      left: '90%',
      width: '650px',
      height: '650px',
    },
    [theme.breakpoints.down('1685')]: {
      top: '37%',
      left: '90%',
      width: '650px',
      height: '650px',
    },
    [theme.breakpoints.down('md')]: {
      left: '92%',
      top: '41%',
      width: '550px',
      height: '550px',
      // boxShadow: '0 0 0 40px #2a7abc, 0 0 0 80px #002d5c',
    },

    // [theme.breakpoints.between(1336, 1575)]: {
    //   width: "510px",
    // },
    // [theme.breakpoints.between(1326, 1336)]: {
    //   width: "500px",
    // },
    // [theme.breakpoints.between(1280, 1326)]: {
    //   width: "465px",
    // },
    // [theme.breakpoints.between(1121, 1280)]: {
    //   width: "510px",
    // },
    // [theme.breakpoints.between(1000, 1121)]: {
    //   width: "408px",
    //   height: "635px",
    // },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  lightBlueEllipse: {
    position: 'absolute',
    width: '54px',
    height: '53px',
		transform: 'translateX(-50%) translateY(-50%)',
		top: '7%',
    left: '72%',
    background: '#2A7ABC',
    mixBlendMode: 'normal',
    borderRadius: '100%',
    [theme.breakpoints.down('lg')]: { top: '7%', left: '66%', },
    // [theme.breakpoints.down('md')]: { left: '775px' },
    [theme.breakpoints.down('sm')]: { display: 'none' },
  },
  greenEllipse: {
    position: 'absolute',
    width: '490px',
    height: '490px',
    transform: 'translateX(-50%) translateY(-50%)',
    top: '38%',
    left: '83%',
    opacity: 0.9,
    background: '#3FAE29',
    mixBlendMode: 'normal',
    borderRadius: '100%',
    zIndex: 2,
    [theme.breakpoints.down('lg')]: { top: '38%', left: '81%' },
    [theme.breakpoints.down('md')]: { width: '450px',
    height: '450px', top: '40%', left: '84%' },
    [theme.breakpoints.down('sm')]: { display: 'none' },
  },
  blueEllipse: {
    position: 'absolute',
    width: '410px',
    height: '410px',
		transform: 'translateX(-50%) translateY(-50%)',
    left: '78%',
    top: '78%',
    background: '#002D5C',
    mixBlendMode: 'normal',
    borderRadius: '100%',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: { left: '75%', top: '78%', },
    [theme.breakpoints.down('md')]: { width: '400px',
    height: '400px', left: '76%', top: '78%', },
    [theme.breakpoints.down('sm')]: { display: 'none' },
  },
  heroImgBg: {
    width: '100%',
    height: '100%',
    zIndex: -2,
    [theme.breakpoints.down('sm')]: {
      left: 0,
      top: 0,
    },
  },
  mobileHeroImgBg: {
    // display: 'inline-block',
    position: 'absolute',
    // top: -80,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -2,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  heroContent: {
    textAlign: 'left',
    position: 'relative',
    left: '0',
  },
  text: {
    color: theme.workwaveBlue,
    lineHeight: '.7',
  },
  imgCont: {
    padding: '2rem 0',
    maxWidth: '100vw',
    overflowX: 'hidden',
    background:
      'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',

    [theme.breakpoints.down('xs')]: {
      paddingTop: '2rem',
      paddingBottom: '1rem',
    },
  },
  imgRight: {
    marginLeft: '-10rem',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '-9rem',
      height: '560px',
    },
    // [theme.breakpoints.down('md')]: {
    // 	marginLeft: '-4rem',
    // 	height: '380px',
    // },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-8rem',
      height: '300px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
      height: '260px',
    },
  },
  pills: {
    border: '1px solid #E5EAF4',
    borderRadius: '8px',
    background: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    transition: 'all .5s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  heroHeader: {
    lineHeight: '4.1875rem',
    letterSpacing: '.2px',
    fontWeight: 700,
    fontSize: '3.9rem',
    color: theme.workwaveBlue,
    maxWidth: '700px',

    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      lineHeight: '60px',
      textAlign: 'left',
      // maxWidth: '550px',
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '32px',
      fontSize: '2.5rem',
      marginLeft: '0',
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  heroSubheader: {
    color: theme.white,
    letterSpacing: '.2px',
    lineHeight: '53px',
    fontWeight: 700,
    fontSize: '3.7813rem',
    marginTop: '10px',
    marginBottom: '16px',
    marginLeft: '5px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '-6px',
      textAlign: 'center',
      fontSize: '2.625rem',
      lineHeight: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    },
  },
  heroBodyContent: {
    lineHeight: 1.25,
    fontSize: '1.6rem',
    color: '#4B5B69',
    fontWeight: 700,
    textAlign: 'left',
    marginTop: '0px',
    letterSpacing: '0.0125rem',
    textTransform: 'capitalize',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('sm')]: {
      // fontSize: '1.375rem',
      fontWeight: 600,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },

  heroSearchInput: {
    display: 'none',
  },

  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    minHeight: '100%',
    minWidth: '100%',
    border: 'none',
    marginTop: '0',
    zIndex: 10,
    borderRadius: '20px',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      marginBottom: '-50px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  waveEdge: {
    position: ' absolute',
    left: 0,
    bottom: 0,

    //ignore the css below. It's just to create a css triangle but we need an SVG for this shape because of the "curve" this triangle has in the mockup.
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '300px 0 0 400px',
    borderColor: 'transparent transparent transparent #2F7FC1',
    [theme.breakpoints.down('lg')]: {
      borderWidth: '240px 0 0 400px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const Hero = ({ hero, industries, homeHeroVideo, setHeroModalOpen }) => {
  const classes = useStyles();
  const searchRef = useRef(null);
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [rotationWords] = useState(['FIRST', 'FUTURE', 'REAL DEAL']);
  const [selectedWord, setSelectedWord] = useState(0);

  useEffect(() => {
    let rotationId;

    rotationId = setInterval(() => {
      setSelectedWord((selected) =>
        selected < rotationWords.length - 1 ? selected + 1 : 0
      );
    }, 2700);

    return () => clearInterval(rotationId);
  }, [selectedWord]);

  //search term change handler
  const handleChange = (e, value) => {
    const selected = industries.edges.filter(
      (industry) => industry.node.title === value
    );
    if (selected.length) {
      setSelectedSlug(selected[0].node.slug.current);
      navigate(`/industries/${selected[0].node.slug.current}`);
    } else {
      setSelectedSlug(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    selectedSlug && navigate(`/industries/${selectedSlug}`);
  };

  const lg = useMediaQuery('(max-width: 1279px)');
  const md = useMediaQuery('(max-width: 960px)');

  const Rotation = () => (
    <>
      {rotationWords[selectedWord].split('').map((txt, index) => (
        <ReactTextTransition
          direction="down"
          inline
          key={index}
          style={{
            lineHeight: md ? 1.2 : '38px',
            color: '#3FA529',
            textTransform: 'capitalize',
            marginRight: txt === 'L' && index === 3 ? '10px' : '0px',
          }}
        >
          {txt}
        </ReactTextTransition>
      ))}
      <br />
    </>
  );
  const pillSortOrder = [
    '2b385d35-6b66-4343-a54b-f2a5e0304b15',
    '7f124ad1-2f10-4049-a53d-3e57317c2a1e',
    '30f856cf-e3bc-4add-b9dd-e58ebb087ca5',
    'ed7439fc-9a20-4fef-b10d-a4916b50ac5b',
  ];
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.root}
      >
        <span className={classes.lightBlueEllipse} />
        <span className={classes.greenEllipse} />
        <span className={classes.blueEllipse} />
        <section className={classes.heroVideoBg}>
          <img
            src={hero.videoImage?.asset?.gatsbyImageData.images.fallback.src}
            alt="home-hero"
            className={classes.heroImgBg}
          />
          {!md && (
            <Video
              url="https://workwave.wistia.com/medias/geez2kf6ip"
              playing={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          )}
          <div className={classes.bgBlueLight} />
          <div
            className={classes.playButton}
            onClick={() => setHeroModalOpen(true)}
          >
            <FontAwesomeIcon icon={['fas', 'play']} size="lg" />
          </div>
        </section>
        {md && (
          <img
            src={
              hero.mobileBackground?.asset?.gatsbyImageData.images.fallback.src
            }
            alt="home-hero"
            className={classes.mobileHeroImgBg}
          />
        )}
        <Container style={{ position: 'inherit', zIndex: '2' }}>
          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            className={classes.heroContent}
            xs={12}
            md={8}
          >
            {hero._rawContent.map((content, index) => (
              <PortableText
                key={index}
                content={content}
                className={classes.text}
                serializers={{
                  h1: ({ children }) => (
                    <Typography variant="h2" className={classes.heroHeader}>
                      {children}
                    </Typography>
                  ),

                  h4: ({ children }) => (
                    <Typography variant="h4" className={classes.heroSubheader}>
                      {children}
                    </Typography>
                  ),
                  normal: ({ children }) => {
                    return (
                      <Typography
                        variant="body1"
                        component="h1"
                        className={classes.heroBodyContent}
                        style={{
                          lineHeight: md ? 1.2 : '38px',
                        }}
                      >
                        {children}
                      </Typography>
                    );
                  },
                  strong: () => (
                    <>
                      {' '}
                      <Rotation />
                    </>
                  ),
                }}
              />
            ))}{' '}
          </Grid>
          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            alignItems={md ? 'center' : 'flex-start'}
            // className={classes.heroContent}
            xs={12}
            md={6}
            lg={8}
          >
            <IndustrySearch
              className={classes.heroSearchInput}
              industries={industries}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              searchRef={searchRef}
            />
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent={md ? 'center' : 'flex-start'}
            spacing={2}
            xs={12}
            md={10}
            style={{ marginTop: '.5rem' }}
            className={classes.heroPills}
          >
            {industries.edges
              .filter((industry) => industry.node.primary)
              .sort(
                (a, b) =>
                  pillSortOrder.indexOf(a.node._id) -
                  pillSortOrder.indexOf(b.node._id)
              )
              .map((industry, index) => (
                <Grid
                  item
                  key={index}
                  style={{ position: 'relative' }}
                  className={classes.heroPill}
                >
                  <Link
                    to={`/industries/${industry.node.slug.current}`}
                    style={{ textDecoration: 'none', position: 'inherit' }}
                  >
                    <div className={classes.pills}>
                      <FontAwesomeIcon
                        icon={['fad', industry.node.faIcon]}
                        style={{
                          height: '35px',
                          width: '35px',
                          border: `1px solid #E5EAF4`,
                          background: '#E5EAF4',
                          padding: '6px',
                          color:
                            industry.node.accentColor[0]?.hexValue ?? '#002D5C',
                          borderTopLeftRadius: '6px',
                          borderBottomLeftRadius: '6px',
                        }}
                      />
                      <Typography
                        variant="subtitle2"
                        style={{
                          textAlign: 'center',
                          width: '100%',
                          color: '#002D5C',
                          fontWeight: 500,
                          padding: '0 15px',
                          position: 'relative',
                          lineHeight: '1.1',
                        }}
                      >
                        {industry.node.homeHeroPillTitle}
                      </Typography>
                    </div>
                  </Link>
                </Grid>
              ))}
            {/* <Grid
							item
							style={{ position: 'relative' }}
							className={classes.heroPill}>
							<Link
								to={`/industries/`}
								style={{ textDecoration: 'none', position: 'inherit' }}>
								<div className={classes.pills}>
									<FontAwesomeIcon
										icon={['fad', 'circle-plus']}
										style={{
											height: '35px',
											width: '35px',
											border: `1px solid #E5EAF4`,
											background: '#E5EAF4',
											padding: '6px',
											color: '#002D5C',
											borderTopLeftRadius: '6px',
											borderBottomLeftRadius: '6px',
										}}
									/>
									<Typography
										variant='subtitle2'
										style={{
											textAlign: 'center',
											width: '100%',
											color: '#002D5C',
											fontWeight: 500,
											padding: '0 15px',
											position: 'relative',
											lineHeight: '1.1',
										}}>
										All Industries
									</Typography>
								</div>
							</Link>
						</Grid> */}
          </Grid>
          {md && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              item
              xs
            >
              <div className={classes.playerWrapper}>
                <Video
                  url={homeHeroVideo}
                  className={classes.reactPlayer}
                  height="100%"
                  width="100%"
                  controls={true}
                />
              </div>
            </Grid>
          )}
        </Container>

        <WaveUpHero
          height="213"
          width="100%"
          fill="white"
          home={true}
          style={{ zIndex: '2' }}
        />
      </Grid>
    </>
  );
};
