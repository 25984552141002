import React from 'react';
import PortableText from 'react-portable-text';
import { BgImage } from 'gbimage-bridge';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Container, useMediaQuery } from '@material-ui/core';

import g2Image from '../../../../images/rg-g2-badges.svg';

const useStyles = makeStyles((theme) => ({
	imgCont: {
		padding: '3rem 0',
		maxWidth: '100vw',
		height: '100%',
		overflowX: 'hidden',
		backgroundSize: 'cover',
		borderTop: '10px solid #3FAE29',
		[theme.breakpoints.down('sm')]: {
			padding: '2rem 0',
		},
	},

	badgesGrid: {
		justifyContent: 'space-between',
		alignItems: 'center',

		[theme.breakpoints.down('md')]: {
			justifyContent: 'space-evenly',
		},
	},

	stat: {
		lineHeight: 0.9,
		color: 'white',
		fontSize: '5.625rem',
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {
			
			fontSize: '4.5rem',
		},
	},
	text: {
		// lineHeight: '32px',
		color: 'white',
		fontWeight: 600,
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.25rem',
			marginBottom: '1rem',
		},
	},
	g2Image: {
		width: '430px',
		maxWidth: '100%',
		[theme.breakpoints.down('md')]: {
			marginTop: '1rem',
		},
	},
}));

export const StatBand = ({ body }) => {
	const classes = useStyles();
	const { statBandBackground, statBand } = body;

	const lg = useMediaQuery('(max-width: 1280px)');

	return (
		<BgImage
			className={classes.imgCont}
			image={statBandBackground?.asset?.gatsbyImageData}
			style={
				{
					// lineHeight: '0',
					// overflow: 'hidden',
					// paddingBottom: lg ? '2rem' : '1rem',
				}
			}>
			<Container>
				<Grid container className={classes.badgesGrid}>
					{statBand.map((stat, index) => (
						// <Grid item container justifyContent='center'>
						<PortableText
							content={stat._rawStats}
							key={index}
							serializers={{
								h3: ({ children }) => (
									<Typography
										variant='h3'
										className={classes.stat}
										style={{ marginTop: index === 0 && lg && '0rem' }}>
										{children}
									</Typography>
								),
								normal: ({ children }) => (
									<Typography variant='h6' className={classes.text}>
										{children}
									</Typography>
								),
							}}
						/>
						// </Grid>
					))}
						<img src={g2Image} className={classes.g2Image}></img>
				</Grid>
			</Container>
		</BgImage>
	);
};
